import { defaultCalculatorsList } from './calculators';

export const defaultSettings = {
  interestRateAnnual: 4.5, // percents
  homePrice: 350000,
  downPaymentPercent: 20, // percents
  loanTerm: '10', //years
  propertyTaxRate: 1.5, // percents
  defaultPropertyTaxRate: 1.5,
  propertyTax: 5250,
  homeInsuranceRate: 0.4, // percents
  defaultHomeInsuranceRate: 0.4,
  homeInsurance: 1400,
  ratesState: 'CA', // state, required
  loanLimitsCounty: 'SAN DIEGO', // required
  isTaxEnabled: true,
  creditScore: 740,
  zipCode: '92123', // required
  // loanLimits: loanLimits // required - auto populate from state and county
  defaultCalculator: 'refinance', // conventional
  calculatorsList: defaultCalculatorsList,
};
