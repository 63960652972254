import { saasWidgetTabList } from '@trendcapital/loan-calculators-widget/src/config';

export const calculatorTabs = saasWidgetTabList.map((tab) => {
  return { [tab.abbr]: tab.title };
});

export const defaultCalculatorsList = saasWidgetTabList.reduce((accumulator, tab) => {
  accumulator[tab.abbr] = true;
  return accumulator;
}, {});
