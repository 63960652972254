import React from 'react';

import cn from 'classnames';

import style from './Loading.scss';

const Loading = () => (
  <div className={cn(style.spinner, style.container)}>
    <div className={style.inner}>
      <div className={style.spinner}>
        <div>
          <div className={style.circle}></div>
          <div className={style.circle}></div>
        </div>
        <div>
          <div className={style.circle}></div>
          <div className={style.circle}></div>
        </div>
      </div>
    </div>
  </div>
);

export default Loading;
